/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-04-12",
    versionChannel: "nightly",
    buildDate: "2024-04-12T00:05:45.113Z",
    commitHash: "1bcc4d530a2440eca86f1d64db9106be8e8047fd",
};
